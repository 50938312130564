<template>
  <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      width="1200"
      persistent
  >
    <v-card  class="overflow-x-hidden overflow-y-hidden px-2">
      <v-card-title class="primary2 py-1 mx-n3 sticky-title">
        <span class="white-color font-weight-medium font-size15">{{$t('Create-Televisit')}}</span>
        <v-spacer />
        <v-icon small color="white" @click="$emit('close')">mdi-window-close</v-icon>
      </v-card-title>
      <v-card-text class="px-2 overflow-y-auto appointment-dialog-custom">
        <v-row dense>
          <v-col cols="4">
            <v-row>
              <v-col cols="12" class="ma-0 pa-0 px-1 mt-6">
                <v-text-field
                    hide-details
                    v-model="appointmentTitle"
                    :label="$t('Title')"
                    outlined
                    class="mx-2 border-radius8"
                    :rules="rules"
                    dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="userRole !== 'DOCTOR'" class="ma-0 pt-2 px-0 pb-0">
                <v-select
                    v-model="doctorId"
                    label="Doctor"
                    :items="doctors"
                    item-value="id"
                    item-text="fullName"
                    outlined
                    dense
                    hide-details
                    class="mx-3 border-radius8"
                />
              </v-col>
              <v-col cols="12" class="px-1">
                <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    title="">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        hide-details
                        v-model="appointmentDate"
                        :label="$t('Date')"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined dense
                        class="mx-2 border-radius8"
                        data-cy="date-text-field"
                    >

                    </v-text-field>
                  </template>
                  <v-date-picker
                      v-model="appointmentDate"
                      no-title scrollable
                      :min="getMinDate()"
                      @change="menu2 = false"
                      data-cy="date-picker"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="px-1 py-1">
                <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="appointmentTime"
                    transition="scale-transition"
                    offset-y max-width="290px"
                    min-width="290px"
                    :title="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        hide-details
                        v-model="appointmentTime"
                        :label="$t('Time')"
                        prepend-inner-icon="mdi-clock-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        class="pl-0 py-0 mx-2 border-radius8"
                        data-cy="time-text-field">

                    </v-text-field>
                  </template>
                  <v-time-picker
                      v-if="menu1"
                      v-model="appointmentTime"
                      :min="getMinTime()"
                      full-width
                      @click:minute="$refs.menu1.save(appointmentTime)"
                      no-title format="ampm" data-cy="time-picker">

                  </v-time-picker>
                </v-menu>
              </v-col >
              <v-col cols="12" class="px-0">
                <v-textarea
                    v-model="appointmentDescription"
                    :label="$t('description')"
                    outlined
                    dense
                    hide-details
                    class="mx-3 border-radius8"
                    :rows="userRole === 'DOCTOR' ? 7 : 4"
                    no-resize
                    height="100"
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="px-3 pt-0">
                <v-col cols="12" class="px-0" >
                  <v-text-field
                      hide-details
                      v-model="search"
                      :label="$t('find-patient')"
                      @input="filterPatient"
                      prepend-inner-icon="mdi-magnify"
                      outlined
                      dense
                      class="mt-n2 border-radius8"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="px-0 py-0">
                  <v-list class="ma-0 pa-0 appointment-dialog-style" dense :height="$vuetify.breakpoint.height <=800 ? '195px' :'435px'" >
                    <v-list-item-group
                        v-model="patientId"
                        active-class="primary--text"
                    >
                      <template v-for="(item) in patients">
                        <v-list-item :key="item.id" :value="item.id">
                          <template v-slot:default="{ active }">
                            <v-list-item-content class="py-0 my-0" >
                              <v-list-item-title class="d-flex align-left mt-2"><p>{{item.firstName + ' ' + item.lastName}}</p></v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-icon v-if="active" small color="primary">mdi-check</v-icon>
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="8">
            <Calendar :doctor="doctorId"></Calendar>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class=" pt-2 pb-5 d-flex align-center justify-end fixed-bottom">
        <!-- TODO-->
        <v-btn @click="$emit('close')" class="px-10">
          <span>{{ $t("cancel") }}</span>
        </v-btn>
        <v-btn
            width="200"
            :loading="loading"
            class="save-dialog-button"
            :disabled="loading || valid"
            @click="loader = 'loading'"
        >
          <span>{{ $t("save") }}</span>
        </v-btn>
      </v-card-actions>
    </v-card >
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Calendar from '@/views/Client/components/Calendar.vue';

export default {
	components: {
		Calendar,
	},
	props: ['dialog'],
	data () {
		return {
                    search: null,
                    loader: null,
                    loading: false,
                    menu1: false,
                    menu2: false,
                    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                    appointmentTitle: '',
                    appointmentDate: '',
                    appointmentTime: '',
                    appointmentDescription: '',
                    patientId: undefined,
                    patients: [],
                    doctorId: undefined,
		};
	},
	computed: {
		...mapState({
			relatedUsers: state => state.hospitals.medicalStaff,
			userDetails: state => state.authentication.userData,
			relatedPatients: (state) => state.hospitals.relatedPatients,
      loggedUser: (state) => state.authentication.userData,
		}),
		...mapGetters({
      pageHeight: 'pageHeight',
			userRole: 'authentication/getRole',
		}),
		rules () {
			return [(v) => !!v || 'This field cannot be blank!'];
		},
		valid () {
			if (this.appointmentTitle === '' || this.patientId === undefined) {
				return true;
			}
			return false;
		},
    doctors () {
      if (!this.relatedUsers) return [];
      const users = this.relatedUsers.flatMap(o =>
          o.roleId === 3 && o.status === 1
              ? [{ fullName: `${o.firstName} ${o.lastName}`, id: o.id }]
              : [],
      );
      return users;
    },
	},
	watch: {
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];

				await this.save()
					.then(() => {
						this[l] = false;
						this.$emit('close');
					})
					.catch(() => {
						this[l] = false;
					});

				this.loader = null;
			}
		},
	},
	async mounted () {
		await this.initialize();
        if (this.userRole === 'NURSE') {
          const selectedHospital = localStorage.getItem('selectedHospitalAssignedId');
          await this.$store.dispatch('hospitals/getRelatedMedicalStaff', selectedHospital);
        }
	},
	methods: {
    filterPatient () {
      const searchLowerCase = this.search.toLowerCase();
      this.patients = this.relatedPatients.filter(patient => {
        const fullName = patient.firstName + ' ' + patient.lastName;
        const fullNameLowerCase = fullName.toLowerCase();
        const firstNameLowerCase = patient.firstName.toLowerCase();
        return (
            fullNameLowerCase.includes(searchLowerCase) ||
            firstNameLowerCase.includes(searchLowerCase)
        );
      });
    },
		async initialize () {
			this.getPatients();
		},
		async getPatients () {
			const hospitalIdFromStore = this.userDetails.hospitalAssignedData.id;
			if (this.userRole === 'HOSPITAL_ADMIN' || this.userRole === 'DOCTOR' || this.userRole === 'NURSE') {
				await this.$store.dispatch('hospitals/getRelatedPatientsV4', hospitalIdFromStore)
					.then(() => {
						this.patients = this.relatedPatients;
					});
			} else {
				this.patients = [];
			}
		},
		getMinDate () {
			const date = new Date();
			return date.toISOString().split('T')[0];
		},
		getMinTime () {
			var date = new Date();
			if (this.appointmentDate === this.getMinDate()) {
				return `${date.getHours() + ':' + date.getMinutes()}`;
			}
		},
		async save () {
			const body = {
				patientId: this.patientId,
				doctorId: this.userDetails.roleId === 3 ? this.userDetails.id : this.doctorId,
				appointmentDateTime: this.appointmentDate + 'T' + this.appointmentTime + ':00',
				appointmentPlannedDurationInMin: 15,
				appointmentTitle: this.appointmentTitle,
				appointmentDescription: this.appointmentDescription,
			};
			await this.$store.dispatch('appointments/newAppointment', body);
      this.$emit('appointmentSaved');

			// if (this.userRole === 'DOCTOR') {
			// 	// await this.$store.dispatch('appointments/getAppointmentsForDoctor', this.userDetails.id).then(res => {
      //     this.$emit('appointmentSaved', 'doctor');
      //     // window.location.reload();
      //   // });
			// } else {
			// 	// const hospitalid = this.userDetails.hospitalAssignedData.id;
			// 	// await this.$store.dispatch('appointments/getAppointmentsByHospitalId', hospitalid || null).then(res => {
      //     this.$emit('appointmentSaved', 'nurse');
      //     // window.location.reload();
      //   // });
			// }
		},
	},
};
</script>
